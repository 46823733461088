<template>
  <div class="deviceConfigList-wrapper">
    <list ref="list"
          exportPermission="export"
          exportMethod="delay"
          :searchUrl="searchUrl"
          :exportUrl="exportUrl"
          :searchParams.sync="searchParams"
          :headers="headers">
      <template #headerSlot>
        <v-button text="新增"
                  permission="add"
                  @click="addParkingManufacturer"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="停车场厂商名称"
                 v-model="searchParams.manufacturerName"></v-input>
        <v-select label="停车场配置类型"
                  v-model="searchParams.configType"
                  :options="configTypeOps"></v-select>
        <v-select label="状态"
                  v-model="searchParams.status"
                  :options="statusOps"></v-select>
        <v-input label="操作人"
                 v-model="searchParams.updateUser"></v-input>
        <v-datepicker label="最后操作时间"
                      :startTime.sync="searchParams.startTime"
                      :endTime.sync="searchParams.endTime"
                      :maxDate="maxDate"></v-datepicker>
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑"
                  type="text"
                  permission="update"
                  @click="editRoadGate(scope.row)"></v-button>
        <v-button :text="scope.row.status === '1' ? '关闭' : '启用'"
                  type="text"
                  permission="on_off"
                  @click="switchStatus(scope.row)"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import {
  getListURL,
  exportListURL,
  eidtStatus
} from './api'
import {
  statusOps,
  statusMap,
  configTypeOps,
  configTypeMap
} from './map'

export default {
  name: 'DeviceConfigList',
  components: {
  },
  data () {
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      statusOps: statusOps(1),
      configTypeOps: configTypeOps(1),
      maxDate: new Date(),
      areaOps: [
        {
          text: '全部',
          value: undefined
        }
      ],
      extraParams: {},
      searchParams: {
        manufacturerName: '',
        configType: undefined,
        status: undefined,
        startTime: '',
        endTime: '',
        updateUser: ''
      },
      headers: [
        {
          prop: 'manufacturerName',
          label: '停车场厂商名称'
        },
        {
          prop: 'configType',
          label: '停车场配置类型',
          formatter (row) {
            return configTypeMap[row.configType]
          }
        },
        {
          prop: 'parkingName',
          label: '状态',
          formatter (row) {
            return statusMap[row.status]
          }
        },
        {
          prop: 'updateTime',
          label: '最后操作时间'
        },
        {
          prop: 'updateUserName',
          label: '操作人'
        }
      ],
      qrCodeId: '',
      qrCodeObj: {
        name: '',
        code: '',
        address: ''
      },
      dialogQrcode: false,
      dialogQrcodeList: false,
      qrcodeList: []
    }
  },
  computed: {
  },
  created () {
  },
  methods: {
    addParkingManufacturer () {
      this.$router.push({
        name: 'parkingManufacturerForm'
      })
    },
    editRoadGate (row) {
      this.$router.push({
        name: 'parkingManufacturerForm',
        query: {
          id: row.id
        }
      })
    },
    async switchStatus (row) {
      let status = ''
      let result = true
      if (row.status === '1') {
        status = 0
        let confirmText = '此停车场已关联多个道闸设施，取消后将立即生效，是否继续。'
        result = await this.$confirm(confirmText)
      } else {
        status = 1
      }
      if (result) {
        let res = await this.$axios({
          url: eidtStatus,
          params: {
            id: row.id,
            status
          },
          method: 'post',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
        if (res.status === 100) {
          this.$message.success('操作成功')
          this.$refs.list.searchData()
        }
      }
    }
  }
}
</script>
